import { isEmpty } from 'lodash';
import { PAY_LINK } from '@/config/links';
import { linkSourceList } from '@/config/linkSourceList';
import Home from '@/views/Home.vue';
import NotActive from '@/views/NotActive.vue';
import PaymentFailed from '@/views/PaymentFailed.vue';
import Checkout from '@/views/Checkout.vue';
import Payermax from '@/views/Payermax.vue';
import Menu from '@/views/Menu.vue';
import Location from '@/views/Location.vue';
import BillNotFound from '@/views/BillNotFound.vue';

export default [
  {
    path: '/not-active',
    name: 'not-active',
    component: NotActive,
  },
  {
    path: '/payment-failed',
    name: 'payment-failed',
    component: PaymentFailed,
    props: ({ query }) => ({
      ...query,
    }),
  },
  {
    path: '/payermax',
    name: 'payermax',
    component: Payermax,
    props: ({ params, query }) => {
      const {
        staff,
        currency = '',
        feeChecked,
        payoutId = '',
        comment = '',
        stars = 0,
        amount = 0,
        totalAmount = 0,
        feeAmount = 0,
        totalTipsFee = 0,
        staffPayoutId = '',
        workplacePayoutId = '',
        partnerId = '',
        groupId = '',
        currencyLabel = '',
        currencyIso = '',
        currencyRightPosition = '',
        backUrl,
        paymentStatus = '',
        paymentId = '',
        isCommon,
        locationName,
        status = '',
        billId = '',
      } = query;

      const parsedAmount = parseFloat(amount, 10);
      const parsedTotalAmount = parseFloat(totalAmount, 10);
      const parsedFeeAmount = parseFloat(feeAmount, 10);
      const parsedTotalTipsFee = parseFloat(totalTipsFee, 10);
      const listPayment = query.listPayment
        ? JSON.parse(query.listPayment)
        : [];
      const reviews = query.reviews
        ? JSON.parse(query.reviews)
        : '';

      if (listPayment.length === 0) {
        listPayment.push({
          payoutId,
          comment,
          stars: parseInt(stars, 10),
          reviews,
          amount: parsedAmount,
        });
      }

      const currencyInfo = {
        iso: currencyIso,
        label: currencyLabel,
        rightPosition: Boolean(+currencyRightPosition),
      };

      const staffData = listPayment.length === 1
        ? JSON.parse(staff || null)
        : null;

      const billCustomerInfo = !isEmpty(query.billCustomerInfo)
        ? JSON.parse(query.billCustomerInfo)
        : {};

      return {
        staffData,
        locationName,
        isCommon: isCommon === 'true',
        feeChecked: feeChecked === 'true',
        currency,
        amount: parsedAmount,
        totalAmount: parsedTotalAmount,
        feeAmount: parsedFeeAmount,
        totalTipsFee: parsedTotalTipsFee,
        groupId: groupId || '',
        staffPayoutId,
        workplacePayoutId,
        partnerId,
        listPayment,
        currencyInfo,
        paymentStatus,
        status: status ? status?.toUpperCase() : '',
        paymentId,
        backUrl: backUrl ? JSON.parse(backUrl) : {},
        billId,
        billCustomerInfo,
      };
    },
  },
  {
    path: '/checkout',
    name: 'checkout',
    component: Checkout,
    props: ({ query }) => {
      const {
        staff,
        currency = '',
        feeChecked,
        payoutId = '',
        comment = '',
        stars = 0,
        amount = 0,
        staffPayoutId = '',
        workplacePayoutId = '',
        partnerId = '',
        groupId = '',
        currencyLabel = '',
        currencyIso = '',
        currencyRightPosition = '',
        backUrl,
        paymentStatus = '',
        paymentId = '',
        isCommon,
        locationName,
      } = query;

      const totalAmount = parseFloat(amount, 10);
      const listPayment = query.listPayment
        ? JSON.parse(query.listPayment)
        : [];
      const reviews = query.reviews
        ? JSON.parse(query.reviews)
        : '';
      const ckoSessionId = query['cko-session-id'] || '';

      if (listPayment.length === 0) {
        listPayment.push({
          payoutId,
          comment,
          stars: parseInt(stars, 10),
          reviews,
          amount: totalAmount,
        });
      }

      const currencyInfo = {
        iso: currencyIso,
        label: currencyLabel,
        rightPosition: Boolean(+currencyRightPosition),
      };

      const staffData = listPayment.length === 1
        ? JSON.parse(staff || null)
        : null;

      return {
        staffData,
        locationName,
        isCommon: isCommon === 'true',
        feeChecked: feeChecked === 'true',
        currency,
        amount: totalAmount,
        groupId: groupId || '',
        staffPayoutId,
        workplacePayoutId,
        partnerId,
        listPayment,
        currencyInfo,
        paymentStatus,
        ckoSessionId,
        paymentId,
        backUrl: backUrl ? JSON.parse(backUrl) : {},
      };
    },
  },
  {
    path: '/:locationPayoutId/menu',
    name: 'menu',
    component: Menu,
    props: ({ params }) => {
      return {
        payoutId: params.locationPayoutId,
      };
    },
  },
  {
    path: '/:locationPayoutId/location',
    name: 'location',
    component: Location,
    props: ({ params }) => {
      return {
        payoutId: params.locationPayoutId,
      };
    },
  },
  {
    path: '/:locationPayoutId/:status?',
    name: 'home',
    component: Home,
    props: ({ params, query }) => {
      const {
        // eslint-disable-next-line camelcase
        session_id,
        // eslint-disable-next-line camelcase
        payment_intent,
        // eslint-disable-next-line camelcase
        payment_intent_client_secret,
        bill = 0,
        amount = 0,
        rate = 0,
        checkoutPaymentId,
        checkoutPayment,
        isApplyCheckoutPayment,
        isGoogleCheckoutPayment,
        isCardCheckoutPayment,
        payPalPayment,
        paymentId,
        isGooglePay,
        isApplePay,
        isPayermaxApplePayPayment,
        isPayermaxGooglePayPayment,
        isPayermaxCardPayment,
        customerEmail,
      } = query;

      const locationPayoutId = parseInt(params.locationPayoutId, 10);
      const payPalCkoSessionId = query?.['cko-session-id'] || '';
      const payPalPaymentId = query?.paymentId || '';

      let staffId = query.staffid || query.staffId || [];
      if (!Array.isArray(staffId) && staffId !== undefined) {
        staffId = [staffId];
      }
      const groupId = query.groupid || query.groupId;
      const feeAmount = (query.feeamount || query.feeAmount) || 0;
      const feeChecked = (query.feechecked || query.feeChecked) || true;
      const qrId = query?.qrId || '';
      const orderId = query?.billId || '';

      const source = query?.source && linkSourceList.includes(query?.source)
        ? query?.source
        : '';

      staffId = staffId.map((value) => parseInt(value, 10).toString());

      return {
        ...params,
        source,
        qrId,
        orderId,
        locationPayoutId,
        staffPayoutId: staffId,
        groupPayoutId: groupId,
        bill: parseFloat(bill),
        amount: parseFloat(amount),
        feeAmount: parseFloat(feeAmount),
        feeChecked: Boolean(+feeChecked || feeChecked === 'true'),
        sessionId: session_id,
        rate: parseInt(rate, 10),
        paymentIntent: payment_intent,
        paymentIntentClientSecret: payment_intent_client_secret,
        paymentId,
        isGooglePay,
        isApplePay,
        checkoutPaymentId,
        checkoutPayment: Boolean(checkoutPayment),
        isApplyCheckoutPayment: Boolean(isApplyCheckoutPayment),
        isGoogleCheckoutPayment: Boolean(isGoogleCheckoutPayment),
        isCardCheckoutPayment: Boolean(isCardCheckoutPayment),
        payPalPayment: Boolean(payPalPayment),
        payPalPaymentId,
        payPalCkoSessionId,
        payermaxPaymentId: paymentId,
        isPayermaxApplePayPayment: Boolean(isPayermaxApplePayPayment),
        isPayermaxGooglePayPayment: Boolean(isPayermaxGooglePayPayment),
        isPayermaxCardPayment: Boolean(isPayermaxCardPayment),
        customerEmail,
      };
    },
  },
  {
    path: '/bill-not-found',
    name: 'bill-not-found',
    component: BillNotFound,
    props: ({ query }) => {
      const { locationPayoutId, locationName } = query;
      return { locationPayoutId, locationName };
    },
  },
  {
    path: '/',
    name: 'fallback',
    beforeEnter() {
      window.location.href = PAY_LINK;
    },
  },
];
