<template>
  <ui-card>
    <div class="bill-info">
      <div class="bill-info__amount">
        <div class="bill-info__amount-label">
          Amount to pay:
        </div>

        <div class="bill-info__amount-value">
          {{ billAmount }}
        </div>
      </div>

      <bill-list :items="billTaxList" />

      <bill-discount
        v-if="billDiscount.percentage"
        :data="billDiscount"
        :currency="currency"
      />

      <bill-list
        v-if="isDepositUnpad || isWaitingForFullPayment"
        :items="billDepositList"
      />

      <bill-list
        v-if="billTipsList?.length"
        :items="billTipsList"
      />

      <div class="bill-info__fee">
        <slot name="platform-fee" />
      </div>

      <ui-button
        transparent
        class="bill-info__button"
        @click="handleShowDetails"
      >
        Show full receipt
      </ui-button>
    </div>

    <bill-info-details
      v-model="showDetails"
      :currency="currency"
      :items="billServiceList"
      :is-deposit="isDepositUnpad || isWaitingForFullPayment"
      :bill-amount="billAmount"
      :bill-tax-list="billTaxList"
      :bill-discount="billDiscount"
      :bill-deposit="billDepositList"
      :bill-tips="billTipsListIncludingFee"
    />
  </ui-card>
</template>

<script setup>
import { ref, computed, toRefs } from 'vue';
import Money from '@/class/Money';
import UiCard from '@/components/UiCard.vue';
import UiButton from '@/components/UiButton.vue';
import BillInfoDetails from './BillInfoDetails.vue';
import BillDiscount from './BillDiscount.vue';
import BillList from './BillList.vue';

const props = defineProps({
  data: Array,
  feeChecked: Boolean,
  feeAmountSum: Number,
  totalSum: Number,
  currency: Object,
});

const {
  data,
  feeChecked,
  feeAmountSum,
  totalSum,
  currency,
} = toRefs(props);

const showDetails = ref(false);

const status = computed(() => {
  return data.value?.status;
});

const isDepositUnpad = computed(() => {
  return status.value === 'DEPOSIT_UNPAID';
});

const isWaitingForFullPayment = computed(() => {
  return status.value === 'WAITING_FOR_FULL_PAYMENT';
});

const isDepositFlow = computed(() => {
  return Boolean(data.value?.depositInfo?.depositAmount?.amount);
});

const tipsAmount = computed(() => {
  return data.value?.tipsDetails?.netTipsAmount?.amount;
});

const billDiscount = computed(() => ({
  percentage: data.value?.discount,
  amount: data.value?.totalWithoutDiscount?.amount || 0,
}));

const billAmount = computed(() => {
  const tipsTotal = Number(totalSum.value || 0);

  const billTotal = isDepositUnpad.value || isWaitingForFullPayment.value
    ? data.value?.amountToPay?.amount || 0
    : data.value?.total?.amount || 0;

  const total = tipsAmount.value || 0
    ? billTotal - (feeChecked.value ? 0 : feeAmountSum.value)
    : billTotal + tipsTotal;

  return new Money(total, currency.value);
});

const billTaxList = computed(() => {
  return data.value?.taxes?.reduce((acc, item) => {
    const name = item.name.split(' ').join('_').toLowerCase();

    return [
      ...acc,
      {
        id: name,
        label: `${item.name} included`,
        value: new Money(item.value.amount, currency.value),
      },
    ];
  }, []);
});

const billServiceList = computed(() => {
  if (!data.value?.services?.length) {
    return [];
  }

  return data.value.services.map((item) => ({
    ...item,
    price: new Money(item.price.amount, currency.value),
    total: new Money(item.total.amount, currency.value),
  }));
});

const billDepositList = computed(() => {
  return [
    {
      id: 'order-amount',
      label: 'Order amount',
      value: isDepositFlow.value && tipsAmount.value
        ? new Money(data.value?.bill?.amount || 0, currency.value)
        : new Money(data.value?.total?.amount || 0, currency.value),
    },
    {
      id: 'order-deposit',
      label: isDepositUnpad.value
        ? `Deposit, ${data.value.depositInfo?.depositPercent}%`
        : `Paid amount, ${data.value.depositInfo?.depositPercent}%`,
      value: new Money(data.value.depositInfo?.depositAmount?.amount || 0, currency.value),
    },
  ];
});

const billTipsList = computed(() => {
  return [
    {
      id: 'order-amount',
      label: 'Order amount',
      value: new Money(data.value?.bill?.amount || 0, currency.value),
      visible: tipsAmount.value && !isDepositFlow.value,
    },
    {
      id: 'order-tips',
      label: 'Tips',
      value: new Money(tipsAmount.value || 0, currency.value),
      visible: tipsAmount.value,
    },
  ].filter(({ visible }) => visible);
});

const billTipsListIncludingFee = computed(() => {
  const totalTips = tipsAmount.value + (feeChecked.value ? feeAmountSum.value : 0);

  return [
    {
      id: 'order-amount',
      label: 'Order amount',
      value: new Money(data.value?.bill?.amount || 0, currency.value),
      visible: tipsAmount.value && !isDepositFlow.value,
    },
    {
      id: 'order-tips',
      label: feeChecked.value
        ? 'Tips, incl. platform fee'
        : 'Tips',
      value: new Money(totalTips || 0, currency.value),
      visible: tipsAmount.value,
    },
  ].filter(({ visible }) => visible);
});

const handleShowDetails = () => {
  showDetails.value = true;
};
</script>
