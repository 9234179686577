<template>
  <div
    class="fee"
    :class="{ 'fee_mt': mt }"
  >
    <ui-checkbox
      v-if="!unconditionalFee"
      :value="feeChecked"
      class="fee__checkbox"
      :class="{
        'fee__checkbox_checked': feeChecked,
      }"
      :disabled="disabled"
      small
      black
      @input="$emit('update:feeChecked', $event)"
    />
    <div class="fee__help">
      <span class="fee__label">
        {{ $t(label, { money }) }}
      </span>

      <modal-fee
        class="fee__info"
        :disabled="disabled"
        @opened="$emit('fee-opened')"
      />
    </div>
  </div>
</template>

<script>
import Money from '@/class/Money';
import UiCheckbox from './UiCheckbox.vue';
import ModalFee from './ModalFee.vue';

export default {
  name: 'AmountFee',

  components: {
    UiCheckbox,
    ModalFee,
  },

  props: {
    currency: {
      type: Object,
      required: true,
    },
    feeChecked: {
      type: Boolean,
      required: true,
    },
    feeAmount: {
      type: Number,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    mt: {
      type: Boolean,
      default: false,
    },
    unconditionalFee: {
      type: Boolean,
      default: false,
    },
    feeLabel: String,
  },

  computed: {
    money() {
      return new Money(this.feeAmount, this.currency);
    },
    label() {
      return this.feeLabel ? this.feeLabel : 'form.fee';
    },
  },
};
</script>

<style lang="scss">
  .fee {
    display: flex;
    align-items: center;
    position: relative;

    &_mt {
      margin-top: 40px;
    }

    &:not(#{&}__checkbox_checked) &__help {
      color: rgba(0,0,0,.5);
    }

    &__checkbox {
      opacity: 0.55;
    }

    &__label {
      font-weight: 200;
      font-size: 11px;
      line-height: 14px;
      color: #b3b3b3;
    }

    &__help {
      display: flex;
      align-items: center;
    }
  }
</style>
